import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import { Product, productPicture } from "./models";
import { getAPI, organisationSettingsServiceApi, S3Service } from "./api";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Select from 'react-select';
import { TelemetryvendingMachinev1Combo, TelemetryvendingMachinev1ComboProduct, TelemetryvendingMachinev1ListCombosResponse } from './telemetry/vending_machine/v1/typescript-axios/api';
import axios from 'axios';
import formatUtilPrice from './format_until_price';
import { formatCents, formatCentsWithDecimalPoints } from './helpers';

interface Props extends IGlobalDataContext {
    match: any;
    product_list?: Product[];
}

const ComboDetail = (props: Props) => {
    const [combo, setCombo] = useState<TelemetryvendingMachinev1Combo>({
        comboUuid: "",
        internalName: "",
        displayName: "",
        price: 0,
        description: "",
        imageUrl: "",
        products: [],
    });
    const [listCombosResponse, setListCombosResponse] = useState<TelemetryvendingMachinev1ListCombosResponse | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<TelemetryvendingMachinev1ComboProduct | null>(null);
    const [productPrice, setProductPrice] = useState<number>(0);
    const [productList, setProductList] = useState<Product[]>(props.product_list || []);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>("");
    const [saving, setSaving] = useState<boolean>(false);
    const [editProductIndex, setEditProductIndex] = useState<number | null>(null);

    const history = useHistory();
    const isEdit = Boolean(props.match.params.comboId);
    const s3Service = useMemo(() => new S3Service(), []);

    useEffect(() => {
        if (!props.product_list) {
            getAPI('/api/products').then((response) => {
                if (response.data.success) {
                    setProductList(response.data.products);
                }
            });
        }
    }, [props.product_list]);

    useEffect(() => {
        const totalProductPrice = combo.products?.reduce((sum, product) => sum + (product.price ?? 0), 0) ?? 0;
        setCombo(prevCombo => ({ ...prevCombo, price: formatCentsWithDecimalPoints(totalProductPrice ?? 0, props.me.mostRecentCompany.currencyDecimalPoints) }));
    }, [combo.products, props.me.mostRecentCompany.currencyDecimalPoints]);


    const loadListCombos = useCallback(() => {
        new organisationSettingsServiceApi().organisationSettingsServiceListCombos({}).then(
            res => {
                setListCombosResponse(res.data);
            }
        ).catch((e) => {
            alert("Failed to load the organization combo list.");
        });
    }, []);

    useEffect(() => {
        loadListCombos();
    }, [loadListCombos]);

    useEffect(() => {
        if (isEdit && listCombosResponse?.combos) {
            const matchingCombo = listCombosResponse.combos.find(c => c.comboUuid === props.match.params.comboId);
            if (matchingCombo) {
                setCombo({
                    comboUuid: matchingCombo.comboUuid ?? "",
                    internalName: matchingCombo.internalName ?? "",
                    displayName: matchingCombo.displayName ?? "",
                    price: formatCentsWithDecimalPoints(matchingCombo.price ?? 0, props.me.mostRecentCompany.currencyDecimalPoints),
                    description: matchingCombo.description ?? "",
                    imageUrl: matchingCombo.imageUrl ?? "",
                    products: matchingCombo.products?.map(p => ({
                        productUuid: p.productUuid,
                        price: p.price,
                    })) ?? [],
                });
                setImagePreviewUrl(matchingCombo.imageUrl ?? "");
            } else {
                alert("Combo not found");
            }
        }
    }, [isEdit, listCombosResponse, props.me.mostRecentCompany.currencyDecimalPoints, props.match.params.comboId]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = useCallback(async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setSaving(true);

        if (!combo.internalName?.trim()) {
            alert("Internal Name is required.");
            setSaving(false);
            return;
        }

        if (!combo.displayName?.trim()) {
            alert("Display Name is required.");
            setSaving(false);
            return;
        }

        if (!combo.products || combo.products.length === 0) {
            alert("At least one product is required in the combo.");
            setSaving(false);
            return;
        }


        let finalImageUrl = combo.imageUrl;
        


        try {
            const totalProductPrice = combo.products?.reduce((sum, product) =>
                sum + (product.price ?? 0), 0) ?? 0;

            const comboPriceInCents = formatCents(
                combo.price?.toString() ?? "0",
                props.me.mostRecentCompany.currencyDecimalPoints
            );

            if (totalProductPrice !== comboPriceInCents) {
                alert(`Combo price (${formatUtilPrice(comboPriceInCents, props.me.mostRecentCompany.currencyDecimalPoints)}) does not match the total price of products (${formatUtilPrice(totalProductPrice, props.me.mostRecentCompany.currencyDecimalPoints)}). Please adjust the combo price or product prices.`);
                setSaving(false);
                return;
            }

            if (imageFile) {
                const extension = imageFile.name.split('.').pop()?.toLowerCase() || 'jpg';
                const presignedUrlResponse = await s3Service.s3ServiceGetS3UploadPath(extension);
                if (presignedUrlResponse.status !== 200) {
                    alert("Failed to get the upload path");
                    setSaving(false);
                    return;
                }

                if (presignedUrlResponse.data?.url) {
                    const uploadResult = await axios.put(presignedUrlResponse.data.url, imageFile);
                    if (uploadResult.status !== 200) {
                        alert("Failed to upload the file");
                        setSaving(false);
                        return;
                    }
                    finalImageUrl = presignedUrlResponse.data.path;
                }
            }

            if (isEdit) {
                await new organisationSettingsServiceApi().organisationSettingsServiceUpdateCombo(
                    props.match.params.comboId,
                    {
                        internalName: combo.internalName,
                        displayName: combo.displayName,
                        price: comboPriceInCents.toString(),
                        description: combo.description,
                        imageUrl: finalImageUrl,
                        products: combo.products?.map(p => ({
                            productUuid: p.productUuid,
                            price: p.price,
                        })),
                    }
                ).then((response) => {
                    alert("Successfully update the combo");
                    history.goBack();
                }).catch((error: any) => {
                    const errorMessage = error.response
                        ? error.response.data?.message
                        : error.message || 'Unknown error';
                    alert(`Failed to update the combo, Error: ${errorMessage}`);
                });
            } else {
                await new organisationSettingsServiceApi().organisationSettingsServiceCreateCombo(
                    {
                        internalName: combo.internalName,
                        displayName: combo.displayName,
                        price: comboPriceInCents,
                        description: combo.description,
                        imageUrl: finalImageUrl,
                        products: combo.products?.map(p => ({
                            productUuid: p.productUuid,
                            price: p.price,
                        })),
                    }
                ).then((response) => {
                    alert("Successfully create the combo");
                    history.goBack();
                }).catch((error: any) => {
                    const errorMessage = error.response
                        ? error.response.data?.message
                        : error.message || 'Unknown error';
                    alert(`Failed to create the combo, Error: ${errorMessage}`);
                });
            }

        } catch (error) {
            alert("Failed to process the request");
        } finally {
            setSaving(false);
        }
    }, [combo, imageFile, isEdit, props, s3Service, history]);

    const handleProductAdd = () => {
        if ((combo.products?.length ?? 0) >= 3) {
            alert("The combo is full. You cannot add more products.");
            return;
        }
        setIsModalOpen(true);
        setSelectedProduct(null);
        setProductPrice(0);
        setEditProductIndex(null);
    };

    const handleProductSave = () => {
        if (!selectedProduct) {
            alert("Please select a product.");
            return;
        }

        if (productPrice <= 0) {
            alert("Please enter a valid price.");
            return;
        }


        const newProduct: TelemetryvendingMachinev1ComboProduct = {
            productUuid: selectedProduct.productUuid,
            price: formatCents(productPrice.toString(), props.me.mostRecentCompany.currencyDecimalPoints),
        };

        setCombo(prevCombo => {
            const products = prevCombo.products ?? [];
            if (editProductIndex !== null) {
                const updatedProducts = [...products];
                updatedProducts[editProductIndex] = newProduct;
                return { ...prevCombo, products: updatedProducts };
            }
            return { ...prevCombo, products: [...products, newProduct] };
        });

        setIsModalOpen(false);
        setEditProductIndex(null);
    };

    const handleProductDelete = (index: number) => {
        const productName = getProductName(combo.products?.[index]?.productUuid);
        if (window.confirm(`Are you sure you want to remove "${productName}" from the combo?`)) {
            setCombo(prevCombo => ({
                ...prevCombo,
                products: prevCombo.products?.filter((_, i) => i !== index),
            }));
        }
    };

    const handleProductEdit = (index: number) => {
        if (combo.products) {
            const productToEdit = combo.products[index];
            setIsModalOpen(true);
            setSelectedProduct(productToEdit);
            setProductPrice(formatCentsWithDecimalPoints(productToEdit.price ?? 0, props.me.mostRecentCompany.currencyDecimalPoints))
            setEditProductIndex(index);
        }
    };

    const productOptions = [...productList]
        .filter(product => product.id !== undefined) 
        .sort((a, b) => (Number(b.id) || 0) - (Number(a.id) || 0)) 
        .map(product => ({
            value: product.uuid,
            label: product.name,
            product_uuid: product.uuid,
            product_name: product.name,
        }));


    const getProductName = (productUuid?: string) => {
        return productList.find(prod => prod.uuid === productUuid)?.name || 'Unknown';
    };

    const getProductUrl = (productUuid?: string) => {
        return productPicture(productList.find(prod => prod.uuid === productUuid) ?? null);
    };

    return (
        <section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>{isEdit ? "Edit Combo" : "Add Combo"}</title>
            </Helmet>
            <h1>{isEdit ? "Edit Combo" : "Add Combo"}</h1>
            <button onClick={() => history.goBack()} className="btn btn-link">
                <i className="bi bi-arrow-left"></i> &lt;Back
            </button>

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Internal Name</label>
                            <input
                                type="text"
                                value={combo.internalName}
                                onChange={(e) => setCombo(prev => ({ ...prev, internalName: e.target.value }))}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Display Name</label>
                            <input
                                type="text"
                                value={combo.displayName}
                                onChange={(e) => setCombo(prev => ({ ...prev, displayName: e.target.value }))}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Price</label>
                            <input
                                type="number"
                                value={combo.price}
                                onChange={(e) => setCombo(prev => ({ ...prev, price: Number(e.target.value) }))}
                                className="form-control"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Description</label>
                            <textarea
                                value={combo.description}
                                onChange={(e) => setCombo(prev => ({ ...prev, description: e.target.value }))}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Combo Image</label>
                            {imagePreviewUrl && <img src={imagePreviewUrl} alt="Combo" style={{ height: "2em" }} />}
                            <input type="file" onChange={handleImageChange} className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                    <h2>Products in Combo</h2>
                    <button
                        type="button"
                        onClick={handleProductAdd}
                        className="btn btn-primary"
                        disabled={(combo.products?.length ?? 0) >= 3}
                    >
                        Add Product
                    </button>
                </div>

                <div className="mt-4 p-4 bg-light rounded shadow-sm">
                    <table className="table table-bordered table-striped table-hover">
                        <thead className="table-primary">
                            <tr>
                                <th>Product</th>
                                <th>Image</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {combo.products?.length === 0 ? (
                                <tr>
                                    <td colSpan={3} className="text-center">No products added yet.</td>
                                </tr>
                            ) : (
                                combo.products?.map((product, index) => (
                                    <tr key={index}>
                                        <td>{getProductName(product.productUuid)}</td>
                                        <td>
                                            <img
                                                src={getProductUrl(product.productUuid)}
                                                alt={getProductName(product.productUuid)}
                                                style={{ height: "2em", objectFit: "cover" }}
                                            />
                                        </td>
                                        <td>{formatUtilPrice(product.price ?? 0, props.me.mostRecentCompany.currencyDecimalPoints)}</td>
                                        <td>
                                            <button
                                                type="button"
                                                onClick={() => handleProductEdit(index)}
                                                className="btn btn-warning"
                                                style={{ marginRight: "0.5em" }}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => handleProductDelete(index)}
                                                className="btn btn-danger"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="form-group">
                    <input
                        type="submit"
                        className="btn btn-success"
                        value={isEdit ? "Save" : "Add"}
                        disabled={saving}
                    />
                    {saving && <span>Saving...</span>}
                </div>
            </form>

            {isModalOpen && (
                <div className="modal show" style={{ display: 'block' }} tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{selectedProduct ? "Edit Product" : "Add Product to Combo"}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Product</label>
                                    <Select
                                        options={productOptions}
                                        value={
                                            selectedProduct
                                                ? productOptions.find(option => option.product_uuid === selectedProduct.productUuid)
                                                : null
                                        }
                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                setSelectedProduct({
                                                    productUuid: selectedOption.product_uuid,
                                                    price: productPrice || 0,
                                                });
                                            } else {
                                                setSelectedProduct(null);
                                            }
                                        }}
                                        placeholder="Select a product"
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Price</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={productPrice}
                                        onChange={(e) => setProductPrice(Number(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setIsModalOpen(false)}>
                                    Close
                                </button>
                                <button type="button" className="btn btn-primary" onClick={handleProductSave}>
                                    Save Product
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default withGlobalData(ComboDetail);